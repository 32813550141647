var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ListModal', {
    ref: "Modal",
    attrs: {
      "title": "Print",
      "load-items": _vm.loadItems,
      "item-key": "xid",
      "item-text": "title",
      "dense": "",
      "selectable": "",
      "action": "Print"
    },
    on: {
      "action": _vm.print
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }