<template>
  <div class="d-flex flex-column ScheduleColumn flex-shrink-0 flex-grow-0 mr-1">

    <v-sheet color='primary white--text mb-1 pa-1 rounded dragMeColumn' elevation="1" @contextmenu="rightClick">
      <div class="text-subtitle-2 text-center">{{ title }}</div>
    </v-sheet>

    <div class="flex-grow-1 overflow-y-auto my-1">
      
      <template v-if="false"></template>

      <v-virtual-scroll :items="[...tasks, { xid: null }]" item-height="80" bench="0" class="">
        <template #default="{item}" :sortable-id='item.xid'>
          <ScheduleTask
            :task="item" class="mx-1"
            :timing="timing"
            :unscheduled="unscheduled"
            @move="moveTask" @updateTiming="updateTiming"
            @complete="completeTask" @deschedule="descheduleTask"
            @highlighted="highlighted" :highlight="highlight"
            @clickEdit="clickEdit(item)"
            @taskflow="v => $emit('taskflow', v)"
          />
        </template>
      </v-virtual-scroll>

    </div>

    <!-- LEFT CLICK POPUP (TASK MENU) -->
    <v-menu
      v-model="contextmenu.show"
      :position-x="contextmenu.x"
      :position-y="contextmenu.y"
      absolute offset-y
      ref="menu_rightclick"
    >
        <v-list dense>
          <v-list-item link @click="print"><v-icon color="primary" class="mr-2">mdi-printer</v-icon>Print</v-list-item>
          <v-list-item link @click="autosort"><v-icon color="primary" class="mr-2">mdi-sort-calendar-ascending</v-icon>Auto Sort</v-list-item>
          <v-list-item link @click="clean"><v-icon color="red" class="mr-2">mdi-trash-can</v-icon>Clean Up Old Tasks</v-list-item>
        </v-list>
    </v-menu>
    
      <ScheduleTask :task="{xid: null}" class="mx-1" @move="moveTask" />
      <EditTaskDialog ref="editTaskDialog" />

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ScheduleColumn{
  width: 300px;

}

.dragMeColumn{
  cursor: move;
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import PrintJS from 'print-js'
import ScheduleTask from './Task'
import EditTaskDialog from '@/components/Dialogs/EditTask'

export default {
  name: 'ScheduleColumn',

  components: {
    ScheduleTask, EditTaskDialog
  },

  props: {
    schedule: { type: Object, default: null, required: true },
    timing: { type: Object, required: true },
    unscheduled: { type: Array, required: true },
    highlight: { type: String, default: null },
  },

  data(){
    return {
      tasks: [],
      sortable_: null,
      contextmenu: { show: false, x: null, y: null },
    }
  },

  computed: {
    ...mapGetters("UI", ["CONTEXT_MENU"]),
    xid(){ return this.schedule.xid; },
    title(){ return this.schedule.title; },
  },

  watch: {
    CONTEXT_MENU(component){
      this.contextmenu = { ...this.contextmenu, show: component == this.$el }
    }
  },

  methods: {
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['SET_CONTEXT_MENU']),
    

    refresh(){
      this.tasks = [];
      this.API({ method: 'GET', endpoint: `schedules/${this.xid}/tasks` })
      .then(tasks => { this.tasks = tasks; })
    },

    moveTask({ task, to }){
      // intercept if task dropped is the "New Task" block
      if(task.xid == '--NEW TASK--'){
       this.createNewTask(to);
        return;
      }
      // get placement position in this column
      let pos = this.tasks.findIndex(t => t.xid == to);
      let currentPos = this.tasks.findIndex(t => t.xid == task.xid);
      if(pos == currentPos && pos !== -1) return; // NO MOVE IF POSITION NO CHANGE AND IN LIST
      // move task position on the server
      let data = { schedule: this.xid, before: to };
      this.API({ method: 'POST', endpoint: `tasks/${task.xid}/schedule`, data });
      // move task position in this column
      if(pos == -1) pos = this.tasks.length; // pos = -1 means place on end
      if(currentPos !== -1 && pos > currentPos) pos -= 1; // if moving down, decrement new pos
      // notify all schedules that task has been dropped (so they can delete from their column)
      this.$emit("taskdrop", { task: task.xid, schedule: this.xid });
      // now add it to this column
      this.tasks.splice(pos, 0, task);
    },

    taskDropped(e){
      // a task is being moved, drop it from the column if we have it
      let task_position = this.tasks.findIndex(t => t.xid == e.task);
      if(task_position !== -1){
        this.tasks.splice(task_position, 1);
      }
    },

    async descheduleTask(task){
      let pos = this.tasks.findIndex(t => t.xid == task.xid);
      if(pos == -1) return;
      await this.API({ method: 'POST', endpoint: `tasks/${task.xid}/deschedule` });
      this.tasks.splice(pos, 1);
      this.$emit('deschedule');
    },

    async completeTask(task){
      let pos = this.tasks.findIndex(t => t.xid == task.xid);
      if(pos == -1) return;
      await this.API({ method: 'POST', endpoint: `tasks/${task.xid}/complete` });
      this.tasks.splice(pos, 1);
      this.$emit('complete');
    },

    updateTiming(){
      this.$emit('complete'); // trigger a timing update
    },

    highlighted(data){
      this.$emit('highlighted', data);
    },

    async createNewTask(to){
      let task = await this.API({ method: 'POST', endpoint: `schedules/${this.xid}/create_task` });
      this.moveTask({ task, to });
      this.clickEdit(task);
    },

    clickEdit(task){
      this.$refs['editTaskDialog'].openDialog(task)
      .then(newData=>{
        let pos = this.tasks.findIndex(t => t.xid == task.xid);
        this.tasks.splice(pos, 1, newData);
        this.updateTiming();
      });
    },

    rightClick(e){
      this.contextmenu = { x: e.clientX, y: e.clientY };
      e.preventDefault();
      this.SET_CONTEXT_MENU(this.$el);
    },

    autosort(){
      this.API({ method: 'POST', endpoint: `schedules/${this.xid}/auto_sort` })
      .then(this.refresh)
      .then(this.updateTiming);
    },

    clean(){
      this.API({ method: 'POST', endpoint: `schedules/${this.xid}/clean` })
      .then(this.refresh)
      .then(this.updateTiming);
    },

    print(){
      this.API({ method: 'GET', endpoint: `schedules/${this.xid}/print` })
      .then(pdf => {
        PrintJS({ printable: pdf, type: 'pdf', base64: true });
      });
    }

  },

  mounted(){
    this.refresh();
  }
}
</script>
