var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "value": _vm.show,
      "width": "85vw",
      "max-width": "1600px",
      "scrollable": "",
      "persistent": ""
    }
  }, [_c('v-card', {
    attrs: {
      "height": "85vh",
      "max-height": "900px"
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v(" Job Queue (" + _vm._s(_vm.jobs.length) + ") "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "dark": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', {
    staticClass: "pa-2"
  }, [_c('v-data-table', {
    staticClass: "fill-height",
    attrs: {
      "items": _vm.jobs,
      "items-per-page": -1,
      "hide-default-footer": "",
      "loading": _vm.loading,
      "headers": _vm.headers,
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.customer",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.customer ? _c('span', [_vm._v(" " + _vm._s(item.customer.name) + " "), item.customer_acc ? _c('span', {
          staticClass: "text-caption font-italic"
        }, [_vm._v("(" + _vm._s(item.customer_acc) + ")")]) : _vm._e()]) : _c('span', {
          staticClass: "text-caption font-italic"
        }, [_vm._v("No customer")])];
      }
    }, {
      key: "item._actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('taskflow', item.xid);
            }
          }
        }, [_vm._v("mdi-source-merge")]), _c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.openJob(item);
            }
          }
        }, [_vm._v("mdi-briefcase")]), _c('v-icon', {
          staticClass: "mx-1",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.scheduleJob(item);
            }
          }
        }, [_vm._v("mdi-plus")])], 1)];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }