<template>
  <div>

    <slot
      name='button'
      v-bind="{ click: open}"
      v-if="PERMS.includes('TASKFLOW_VIEW')"
    ></slot>

    <v-dialog v-model='isOpen' persistent width='1500px' scrollable>
      <v-card :disabled='loading' :loading='loading'>
        <v-card-title class='primary white--text py-2'>Taskflow
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text style='height:1000px;' class="pa-0">

          <v-row class="fill-height ma-0">
            <v-col cols="3" class="overflow-y-auto fill-height">


              <template v-if="task" class="">

                
                
                  <v-select :items='taskTypes' item-text='title' item-value="xid" :value="task.type" return-object label="Type" outlined dense hide-details clearable @input="changeTaskType" />

                  <div class="mt-4">
                    <v-btn text small @click="editDuration" color="primary"><v-icon class="mr-2">mdi-clock-outline</v-icon>{{ task.duration }} minutes</v-btn>
                    <v-checkbox v-model='task.active' label="Schedule" class="mt-1 ml-3" @change="()=>updateTask(task.xid)" hide-details />
                  </div>

                  <v-textarea :value="task.description" outlined dense hide-detadils class='mt-4' label="Description" counter maxlength="255" @input="changeDescription" />

                  
                  <div class="d-flex justify-space-between">
                    <v-btn text small @click="completeTask" class="primary--text">{{ task.completed ? "Incomplete" : "Complete" }}</v-btn>
                    <v-btn text small @click="deleteTask" class="red--text">Delete</v-btn>
                  </div>
                  
                  



                  <v-alert color='primary' outlined icon="mdi-information" class="mt-4" dense>
                      <b>Ctrl+Click</b> to link/unlink items.
                    </v-alert>
                  
              </template>
              <template v-else> 
                <v-btn block class="primary" @click="newTask">New Task</v-btn>

                <p class="text-center mt-4">
                  <v-btn text small color="primary" @click="()=>{ $refs.ImportTaskflow.open(); }">Import Preset Taskflow</v-btn>
                  <ListModal
                    ref="ImportTaskflow" title="Import Taskflow" item-text="title"
                    :loadItems="()=>{ return API({ method: 'GET', endpoint: 'taskflows' }); }"
                  >
                    <template #actions="{item}"><v-icon @click="importTaskflow(item.xid)" color="primary">mdi-import</v-icon></template>
                  </ListModal>
                </p>
              </template>
              
              

            </v-col>
            <v-col cols="9" class="pa-0 grey lighten-2" style="max-height:100%;">
              <Chart v-if='isOpen'
                ref="chart"
                class=""
                :blocks="blocks"
                @link="link"
                @move="move"
                @select="updateSelected"
              >
              <template #block="{block, selected}">
                <div v-for="task in [tasks.find(t=>t.xid==block.id)]" :key="task.xid" style="height:100%;">
                  
                  <v-card class="taskCard fill-height pa-1" :disabled="!task.active" :class="{selected}" >
                    <div class="d-flex">
                      <span 
                        class="my-0 text-truncate font-weight-medium primary--text"
                        :class="{'primary--text': !task.completed, 'success--text': task.completed }"
                      >
                        <template v-if="task.type">{{task.type.title}}</template>
                        <template v-else>Task</template>
                      </span>
                      <v-spacer />

                      <div class="d-flex mr-1 align-center flex-columns">
                        <template v-if="task.completed">
                          <v-icon small color="success">mdi-check</v-icon>
                        </template>
                        <template v-else>
                          <v-icon small class="mr-1 primary--text">mdi-clock-outline</v-icon>
                          <div class="primary--text">{{ task.duration }}</div>
                        </template>
                        
                      </div>
                    </div>
                    <p class="my-0 pa-0 text-truncate text-caption black--text">
                      {{task.description}}&nbsp;
                    </p>
                    <p class="my-0 pa-0 text-truncate text-caption">
                      <span v-if="task.scheduleInfo?.scheduleId">
                        <v-icon x-small color="primary">mdi-chart-timeline</v-icon> <span class="font-italic text-caption">{{ task.scheduleInfo.scheduleName }}</span>
                      </span>
                      <span v-else>
                        <v-icon x-small>mdi-chart-timeline</v-icon> <span class="font-italic text-caption">Not Scheduled</span>
                      </span>
                      <span v-if="task.scheduleInfo?.ETA">
                        &nbsp;<span class="text-caption">{{ localTimestamp(task.scheduleInfo.ETA.s).format('DD/MM/YY') }}</span>
                      </span>
                    </p>

                  </v-card>
                  
                </div>
              </template>
            </Chart>
            </v-col>

          </v-row>
          
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class='primary' @click='isOpen=false'>Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.taskCard{
  &.selected{ border-color: #000 !important; }
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import Moment from 'moment';
import Chart from '@/components/chart/Chart'
import ListModal from '@/components/Common/ListModal'
//import { type } from 'os';

export default {
  name: 'Taskflow',
  components: {
    Chart, ListModal
  },
  props: {
    type: { String, required: true }, // Job or Template
    id: { type: String, required: false, default: null }
  },

  data(){
    return {
      loading: false,
      isOpen: false,
      taskTypes: [],
      tasks: [],
      blocks: [],
      /*blocks: [
        /*{ id: 'a', title: 'Powdercoat', text: 'Text A', x: 50, y: 50, w: 200, h: 50, links: [] },
        { id: 'b', title: 'Glass', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
        { id: 'c', title: 'Screens', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
        { id: 'd', title: 'Assembly', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
        { id: 'e', title: 'Installation', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
        { id: 'f', title: 'Check Measure', text: 'Text B', x: 50, y: 50, w: 200, h: 50, links: [] },
      ],*/
      task: null, //current block
    }
    
  },

  computed: {
    ...mapGetters("AUTH", ['PERMS']),

    tasksEndpoint(){
      let types = { 'job': 'jobs', 'preset': 'taskflows' };
      return `${types[this.type]}/${this.id}/tasks`;
    },

    dependents(){
      let dependents = this.task?.dependents ?? [];
      return this.tasks.filter(t => dependents.includes(t.xid));
    },

  },

  methods: {
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['ALERT', 'CONFIRM', 'PROMPT']),

    refresh(){
      this.loading = true;
      this.tasks = [];
      this.blocks = [];
      this.task = null;
      return this.API({ method: 'GET', endpoint: this.tasksEndpoint })
      .then(this.loadBlocksFromTasks)
      .finally(() => { this.loading = false; });
    },

    loadBlocksFromTasks(tasks){
      this.tasks = tasks;
      this.blocks = tasks.map(this.taskBlock);
    },

    taskBlock(task){
      let t = task;
      return {
        id: t.xid, x: t.x, y: t.y, w: 250, h: 75, links: t.dependents
      };
    },

    loadTaskTypes(){
      return this.API({ method: 'GET', endpoint: 'taskTypes' })
      .then(types => { this.taskTypes = types; });
    },

    open(){
      this.tasks = [];
      this.blocks = [];
      this.task = null;
      if(!this.taskTypes.length) this.loadTaskTypes();
      this.isOpen = true;
      this.refresh();
    },

    link({from, to}){
      from = this.blocks.find(b => b.id == from);
      to = this.blocks.find(b => b.id == to);
      if(!(from && to)) return;
      if(to.links.includes(from.id))
        this.unlink(from.id);
      else{
        to.links.push(from.id);
        this.API({ method: 'POST', endpoint: `tasks/${to.id}/link/${from.id}` });
      }
    },

    unlink(linkedTaskId){
      console.log("UNLINK", linkedTaskId);
      // remove from model
      let linkIndex = this.task.dependents.findIndex(taskId => taskId == linkedTaskId);
      this.task.dependents.splice(linkIndex, 1);
      //update server
      this.API({ method: 'DELETE', endpoint: `tasks/${this.task.xid}/link/${linkedTaskId}` });
    },

    
    async newTask(){
      this.loading = true;
      let newTask = await this.API({ method: 'POST', endpoint: `${this.tasksEndpoint}/create` });
      this.tasks.push(newTask);
      this.loadBlocksFromTasks(this.tasks);
      this.$refs.chart.selected = newTask.xid;
      this.loading = false;
    },

    async deleteTask(){
      this.CONFIRM({ title: 'Delete Task', message: 'Are you sure?' })
      .then(() => {
        this.loading = true;
        this.API({ method: 'DELETE', endpoint: `tasks/${this.task.xid}` })
        .then(this.refresh);
      });
    },

    completeTask(){
      this.loading = true;
      let endpoint = this.task.completed ? "incomplete" : "complete";
      this.API({ method: 'POST', endpoint: `tasks/${this.task.xid}/${endpoint}` })
      .then(task => {
        Object.assign(this.tasks.find(t => t.xid == this.task.xid), task);
        this.loading = false;
      });
    },

    // set selected task on block click
    updateSelected(blockId){ this.task = this.tasks.find(t => t.xid == blockId); },

    // basic updates
    move({ id, x, y }){ this.updateTask(id, { x, y }); },
    changeTaskType(type){
      this.updateTask(this.task.xid, { type, duration: type?.duration ?? 0 });
    },
    changeDescription(description){ this.updateTask(this.task.xid, { description }) },

    // Apply updates to task
    updateTask(taskId, changes){
      changes = changes ?? {};
      // apply changes locally
      let task = this.tasks.find(t => t.xid == taskId);
      Object.assign(task, changes);
      // update the block
      let newBlock = this.taskBlock(task);
      Object.assign(this.blocks.find(b => b.id == task.xid), newBlock);
      // update at the server
      let data = {
        x: task.x,
        y: task.y,
        type: task.type?.xid ?? null,
        description: task.description,
        active: task.active ?? false,
        duration: task.duration,
      }
      this.API({ method: 'PATCH', endpoint: `tasks/${task.xid}`, data, autosave: 2000 });
    },

    async editDuration(){
      let duration = await this.PROMPT({ title: 'Task Duration', message: 'Duration in minutes:', placeholder: this.task.duration });
      if(duration === this.task.duration) return;
      this.loading = true;
      this.API({
        method: 'PATCH',
        endpoint: `tasks/${this.task.xid}`,
        data: { duration }
      })
      .then(() => { this.task.duration = duration; })
      .finally(() => { this.loading = false; });
    },


    async importTaskflow(taskflow){
      await this.CONFIRM({ title: 'Import Taskflow', message: 'This will delete all existing tasks and related scheduling.\nContinue?' });
      this.$refs.ImportTaskflow.close();
      this.loading = true;
      await this.API({ method: 'POST', endpoint: `${this.tasksEndpoint}/import`, data: { taskflow } });
      this.refresh();
    },

    localTimestamp(t){
      return Moment.utc(t).local();
    },

  },

}
</script>
