var render = function () {
  var _vm$timing$_vm$task$x, _vm$timing$_vm$task$x2, _vm$job$customer, _vm$job$stage, _vm$job$stage2, _vm$job$stage3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('div', {
    staticClass: "dropHere rounded",
    class: {
      'primary': _vm.dragover
    }
  }), _vm.xid == null ? [_c('v-sheet', {
    staticClass: "pa-1 grey lighten-2 rounded",
    class: {
      'interceptDrop': _vm.dragover
    },
    staticStyle: {
      "opacity": "1"
    },
    on: {
      "dragenter": _vm.dragEnter,
      "dragleave": _vm.dragLeave,
      "dragover": _vm.dragOver,
      "drop": _vm.dragDrop
    }
  }, [_c('div', {
    staticClass: "text-caption text-center grey--text text--darken-x font-italic"
  }, [_vm._v("End of Queue")])])] : [_c('v-card', {
    staticClass: "pa-1 dragMeTask",
    class: {
      'interceptDrop': _vm.dragover,
      'yellow lighten-3': _vm.isHighlighted
    },
    attrs: {
      "draggable": "true"
    },
    on: {
      "dragstart": _vm.dragStart,
      "dragenter": _vm.dragEnter,
      "dragleave": _vm.dragLeave,
      "dragover": _vm.dragOver,
      "drop": _vm.dragDrop,
      "contextmenu": function contextmenu($event) {
        $event.preventDefault();
        return _vm.menu_rightclick_show.apply(null, arguments);
      },
      "click": _vm.highlighted
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0 ma-0"
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_vm.task.jobNumber ? [_c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "label": "",
      "small": "",
      "color": _vm.colour,
      "title": _vm.message
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.menu_leftclick_show.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.task.jobNumber))])] : _c('v-chip', {
    staticClass: "mr-2",
    attrs: {
      "label": "",
      "small": ""
    }
  }, [_vm._v("No Job")]), _c('div', {
    staticClass: "text-caption primary--text"
  }, [_vm._v(_vm._s((_vm$timing$_vm$task$x = (_vm$timing$_vm$task$x2 = _vm.timing[_vm.task.xid]) === null || _vm$timing$_vm$task$x2 === void 0 ? void 0 : _vm$timing$_vm$task$x2.s.format('DD/MM, HH:mm')) !== null && _vm$timing$_vm$task$x !== void 0 ? _vm$timing$_vm$task$x : ""))]), _vm.promisedDeadline !== null ? _c('div', {
    staticClass: "text-caption",
    attrs: {
      "title": "Days until Job F/C Date"
    }
  }, [_vm.promisedDeadline > 0 ? _c('span', {
    staticClass: "success--text"
  }, [_vm._v(_vm._s(_vm.promisedDeadline))]) : _c('span', {
    staticClass: "error--text"
  }, [_vm._v(_vm._s(_vm.promisedDeadline))])]) : _vm._e(), _c('a', {
    staticClass: "d-flex mr-1 align-center flex-columns primary--text text-caption",
    attrs: {
      "title": "Task Duration (minutes)"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.editDuration.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.task.duration))])], 2), _c('div', {
    staticClass: "d-flex px-1"
  }, [_vm.task.type ? _c('div', {
    staticClass: "text-subtitle-2 secondary--text flex-shrink-0"
  }, [_vm._v(_vm._s(_vm.task.type.title))]) : _c('div', {
    staticClass: "text-subtitle-2 secondary--text"
  }, [_vm._v("Task")]), _c('div', {
    staticClass: "text-caption secondary--text ml-2 text-truncate flex-grow-1 text-center"
  }, [_vm._v(_vm._s(_vm.task.custName))])]), _c('div', {
    staticClass: "text-caption px-1 text-truncate",
    attrs: {
      "title": _vm.task.description
    }
  }, [_vm._v(_vm._s(_vm.task.description)), _vm._v(" ")])])], 1)], _c('v-menu', {
    ref: "menu_rightclick",
    attrs: {
      "position-x": _vm.contextmenu_pos.x,
      "position-y": _vm.contextmenu_pos.y,
      "absolute": "",
      "offset-y": ""
    },
    model: {
      value: _vm.contextmenu_show_right,
      callback: function callback($$v) {
        _vm.contextmenu_show_right = $$v;
      },
      expression: "contextmenu_show_right"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('clickEdit');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-pencil")]), _vm._v("Edit")], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.markComplete
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "green"
    }
  }, [_vm._v("mdi-check")]), _vm._v("Complete")], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.unschedule
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "red"
    }
  }, [_vm._v("mdi-trash-can")]), _vm._v("Deschedule")], 1)], 1)], 1), _c('v-menu', {
    ref: "menu_leftclick",
    attrs: {
      "position-x": _vm.contextmenu_pos.x,
      "position-y": _vm.contextmenu_pos.y,
      "absolute": "",
      "offset-y": "",
      "close-on-content-click": false
    },
    model: {
      value: _vm.contextmenu_show_left,
      callback: function callback($$v) {
        _vm.contextmenu_show_left = $$v;
      },
      expression: "contextmenu_show_left"
    }
  }, [_c('v-card', {
    staticClass: "d-block",
    attrs: {
      "width": "400"
    }
  }, [_c('v-card-text', {
    staticClass: "py-0"
  }, [_vm.job == null ? [_vm._v(" Loading... ")] : [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-avatar', [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-briefcase")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "primary--text font-weight-medium"
  }, [_vm._v(_vm._s(_vm.job.number))]), _c('v-list-item-subtitle', [_vm._v(_vm._s((_vm$job$customer = _vm.job.customer) === null || _vm$job$customer === void 0 ? void 0 : _vm$job$customer.name))]), _c('v-list-item-subtitle', {
    staticClass: "text-caption"
  }, [_vm._v(_vm._s(_vm.job.customer_acc))])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('taskflow', _vm.job.xid);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-source-merge")])], 1)], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": "",
      "color": "primary"
    },
    on: {
      "click": _vm.openJobInNewTab
    }
  }, [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)], 1)], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-content', {
    staticClass: "justify-center text-subtitle-2"
  }, [_vm._v(_vm._s(_vm.job.description))])], 1), _c('v-divider'), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "font-weight-medium d-flex justify-center"
  }, [(_vm$job$stage = _vm.job.stage) !== null && _vm$job$stage !== void 0 && _vm$job$stage.menu_icon ? _c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(_vm._s((_vm$job$stage2 = _vm.job.stage) === null || _vm$job$stage2 === void 0 ? void 0 : _vm$job$stage2.menu_icon))]) : _vm._e(), _c('div', [_vm._v(_vm._s((_vm$job$stage3 = _vm.job.stage) === null || _vm$job$stage3 === void 0 ? void 0 : _vm$job$stage3.title))])], 1), _c('v-list-item-subtitle', {
    staticClass: "mt-1"
  }, [_c('v-row', {
    staticClass: "text-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("ACC: " + _vm._s(_vm.job.date_accepted))]), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("F/C: " + _vm._s(_vm.job.date_promised))])], 1)], 1)], 1)], 1)], 1)]], 2)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }