<template>
  <v-dialog :value="show" width="85vw" max-width="1600px" scrollable persistent>

    <v-card height="85vh" max-height="900px">
      <v-card-title class="primary white--text">
        Job Queue ({{ jobs.length }})
        <v-spacer />
        <v-btn icon dark @click="close"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text class="pa-2">
        <v-data-table class="fill-height" :items='jobs' :items-per-page="-1" hide-default-footer :loading="loading" :headers="headers" dense>

          <template #item.customer="{item}">
            <span v-if='item.customer'>
              {{item.customer.name}}
              <span v-if='item.customer_acc' class='text-caption font-italic'>({{item.customer_acc}})</span>
            </span>
            <span v-else class='text-caption font-italic'>No customer</span>
          </template>

          <template #item._actions="{item}">
            <div class="text-right">
              <v-icon color="primary" @click="$emit('taskflow', item.xid)" class="mx-1">mdi-source-merge</v-icon>
              <v-icon color="primary" @click="openJob(item)" class="mx-1">mdi-briefcase</v-icon>
              <v-icon color="primary" @click="scheduleJob(item)" class="mx-1">mdi-plus</v-icon>
            </div>
            
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
    
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';

export default {
  name: '',
  components: {
  },

  props: {
  },

  data(){
    return {
      show: false,
      loading: false,
      headers: [
        { text: "Job", value: 'number' },
        { text: "Accepted", value: 'date_accepted' },
        { text: "Forecast", value: 'date_promised' },
        { text: "Stage", value: 'stage.title' },
        { text: "Locality", value: 'address.suburb' },
        { text: "Customer", value: 'customer' },
        { text: "", value: '_actions' },
      ],
      jobs: [],
      schedulesChanged: false,
    }
  },

  computed: {
  },

  watch: {
  },

  methods: {
    ...mapActions("API", ["API"]),

    open(){
      this.show = true;
      this.refresh();
      this.schedulesChanged = false;
    },
    close(){
      this.show = false;
      if(this.schedulesChanged) this.$emit("schedulesChanged")
    },

    refresh(){
      this.loading = true;
      this.jobs = [];
      this.API({ endpoint: 'scheduling/job_queue' })
      .then(jobs => { this.jobs = jobs; })
      .finally(() => { this.loading = false; })
    },

    scheduleJob(job){
      this.schedulesChanged = true;
      this.loading = true;
      this.API({ method: 'POST', endpoint: `scheduling/schedule_job/${job.xid}` })
      .then(() => {
        this.jobs = this.jobs.filter(j => j.xid != job.xid);
      })
      .finally(() => { this.loading = false; });
    },

    openJob(job){
      window.open(`/dash/jobs/all/${job.xid}`, '_blank');
    }

  },

  mounted(){
  }
  
}
</script>
