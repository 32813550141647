var render = function () {
  var _vm$autoschedule$sche;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ListModal', {
    ref: "Modal",
    attrs: {
      "title": "Schedules",
      "load-items": _vm.loadSchedules,
      "add-button": "",
      "item-key": "xid",
      "item-text": "title",
      "sortable": ""
    },
    on: {
      "add-item": _vm.addItem,
      "sort": _vm.sortItems
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": "",
            "title": "Rename"
          },
          on: {
            "click": function click($event) {
              return _vm.renameItem(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-rename")])], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": "",
            "title": "Autoschedule Tasks"
          },
          on: {
            "click": function click($event) {
              return _vm.editTasks(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-chart-gantt")])], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": "",
            "title": "Available Minutes/Day"
          },
          on: {
            "click": function click($event) {
              return _vm.editDailyMinutes(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "primary--text"
        }, [_vm._v("mdi-clock-outline")])], 1), _c('v-btn', {
          staticClass: "float-left",
          attrs: {
            "icon": "",
            "title": "Delete Schedule"
          },
          on: {
            "click": function click($event) {
              return _vm.deleteItem(item);
            }
          }
        }, [_c('v-icon', {
          staticClass: "red--text"
        }, [_vm._v("mdi-trash-can")])], 1)];
      }
    }])
  }), _c('ListModal', {
    ref: "AutoscheduleTaskModal",
    attrs: {
      "title": "".concat((_vm$autoschedule$sche = _vm.autoschedule.schedule) === null || _vm$autoschedule$sche === void 0 ? void 0 : _vm$autoschedule$sche.title),
      "load-items": _vm.loadTaskTypes,
      "item-key": "xid",
      "item-text": "title"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('v-checkbox', {
          attrs: {
            "value": item.xid
          },
          on: {
            "change": function change($event) {
              return _vm.select_task_type(item);
            }
          },
          model: {
            value: _vm.autoschedule.task_types,
            callback: function callback($$v) {
              _vm.$set(_vm.autoschedule, "task_types", $$v);
            },
            expression: "autoschedule.task_types"
          }
        })];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }