var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-dialog', {
    attrs: {
      "value": _vm.show,
      "persistent": "",
      "max-width": "500px",
      "scrollable": ""
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text py-2"
  }, [_vm._v("Edit Task")]), _c('v-card-text', {
    staticStyle: {
      "height": "auto"
    }
  }, [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.taskTypes,
      "item-text": "title",
      "item-value": "xid",
      "return-object": "",
      "label": "Type",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.task.type,
      callback: function callback($$v) {
        _vm.$set(_vm.task, "type", $$v);
      },
      expression: "task.type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.editDuration
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-clock-outline")]), _vm._v(_vm._s(_vm.task.duration) + " minutes")], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-detadils": "",
      "label": "Description",
      "counter": "",
      "maxlength": "255"
    },
    model: {
      value: _vm.task.description,
      callback: function callback($$v) {
        _vm.$set(_vm.task, "description", $$v);
      },
      expression: "task.description"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.save
    }
  }, [_vm._v("Save")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }