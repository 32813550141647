<template>
  <div>

    <v-dialog :value='show' persistent  max-width='500px' scrollable>
      <v-card :disabled='loading' :loading='loading'>
        <v-card-title class='primary white--text py-2'>Edit Task</v-card-title>
        <v-card-text style='height: auto;' class=''>
          <v-row class="mt-4">
            <v-col cols="8">
              <v-select :items='taskTypes' item-text='title' item-value="xid" v-model="task.type" return-object label="Type" outlined dense hide-details clearable />
            </v-col>
            <v-col cols="4">
              <v-btn text small @click="editDuration" color="primary"><v-icon class="mr-2">mdi-clock-outline</v-icon>{{ task.duration }} minutes</v-btn>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="task.description" outlined dense hide-detadils class='' label="Description" counter maxlength="255" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class='secondary' @click='close'>Cancel</v-btn>
          <v-btn class='primary' @click='save'>Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import Deferred from '@/helpers/Deferred';
export default {
  name: 'EditTaskDialog',
  components: {},
  props: {
  },
  data(){
    return {
      loading: false,
      show: false,
      resolvePromise: null,
      taskTypes: [],
      task: {
        xid: null,
        type: null,
        description: "",
        duration: 0,
      }
    }
  },
  computed: {},
  methods: {

    ...mapActions("API", ['API']),
    ...mapActions("UI", ['PROMPT']),

    openDialog(task){
      this.show = true;
      this.task = { xid:null, type: null, description: "", duration: 0, ...task };
      this.loadTaskTypes();
      let { promise, resolve } = new Deferred();
      this.resolvePromise = resolve;
      return promise;
    },

    close(){
      this.show = false;
    },

    loadTaskTypes(){
      this.taskTypes = [];
      this.API({ method: 'GET', endpoint: 'taskTypes' })
      .then(types => { this.taskTypes = types; });
    },

    async editDuration(){
      let duration = await this.PROMPT({ title: 'Task Duration', message: 'Duration in minutes:', placeholder: this.task.duration });
      this.task.duration = parseInt(duration) || 0;
    },

    save(){
      this.loading = true;
      let data = {
        description: this.task.description,
        duration: this.task.duration,
        type: this.task.type?.xid ?? null,
      }
      this.API({ method: 'PATCH', endpoint: `tasks/${this.task.xid}`, data })
      .then(task => {
        this.show = false;
        this.resolvePromise(task);
      })
      .finally(() => { this.loading = false; });
    }

  },
  created(){
  },
  mounted(){
  }
}
</script>
