<template>
    <v-container fluid fill-height class="d-flex flex-column flex-nowrap pa-0">

      <!-- MAIN SCHEDULE COLUMN AREA-->
      <div id="scrollArea" class="flex-grow-1 overflow-auto" style="width:100%;">
        <div class="d-flex overflow-x-auto fill-height flex-grow-0 pa-1" ref='sortable'>
            <ScheduleColumn
              v-for="s in schedules" :key="s.xid" :sortable-id='s.xid' ref="schedules"
              :schedule="s"
              :timing="timing"
              :unscheduled="unscheduledTasks"
              @taskdrop="taskdrop"
              @complete="refreshTiming" @deschedule="deschedule"
              @highlighted="highlighted" :highlight="highlight"
              @taskflow="showTaskflow"
            />
        </div>
      </div>

      <!-- TOOLBAR -->
      <div id="toolbarArea" class="" style="width:100%;position:relative;">
        <v-divider />
        <div class="grey lighten-3 pa-2 text-caption">
          <v-btn small color="primary mr-4" @click="OpenScheduleList">Manage Schedules</v-btn>
          <v-btn small color="primary mr-4" @click="ClearJobQueue">Clear All</v-btn>
          <v-btn small color="primary mr-4" @click="ClearOldJobTasks">Clear Old</v-btn>
          <v-btn small color="primary mr-4" @click="OpenJobQueue">Job Queue</v-btn>
          <v-btn small color="primary mr-4" @click="SortAll">Sort All</v-btn>
          <v-btn small color="primary mr-4" @click="Print">Print</v-btn>
        </div>
      </div>

      <!-- EMBEDDED COMPONENTS -->
      <ScheduleList ref="ScheduleList" @changed="refreshSchedules" />
      <JobQueue ref="JobQueue" @schedulesChanged="refreshSchedules" @taskflow="showTaskflow" />
      <PrintSchedules ref="PrintSchedules" />
      <Taskflow ref="Taskflow" type="job" :id="taskflowJobId" />
      <!--<Unscheduled ref="unscheduled"  @highlighted="highlighted" :highlight="highlight" @autoplace="autoplace" />-->


      <!-- MAGIC WAND LOADER OVERLAY -->
      <v-overlay
          absolute
          :value="magic_text"
        >
        <v-card light width="400px">
          <v-card-text class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate class="my-4">
                <v-icon color="primary">mdi-auto-fix</v-icon>
              </v-progress-circular>
              <div class="my-6 secondary--text">{{ magic_text }}</div>
          </v-card-text>
        </v-card>
      </v-overlay>

    </v-container>
</template>

<style lang="scss" scoped>
</style>


<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import Sortable from 'sortablejs'
import ScheduleList from '@/components/Schedule/ScheduleList';
import ScheduleColumn from '@/components/Schedule/Column'
import PrintSchedules from '@/components/Schedule/Print'
//import Unscheduled from '@/components/Schedule/Unscheduled'
import JobQueue from '@/components/Schedule/JobQueue'
import Taskflow from '@/components/Dialogs/Taskflow'
import moment from 'moment'

export default {
  name: 'TaskManager',
  components: {
    ScheduleList, ScheduleColumn, JobQueue, PrintSchedules, Taskflow
  },
  data(){
    return {
      magic_text: "",
      schedules: [],
      sortable_: null,
      timing: {},
      highlight: null,
      taskflowJobId: null,
    };
  },
  computed: {
    unscheduledTasks(){ return this.$refs.unscheduled?.tasks?.map(t=>t.xid) ?? []; },
  },
  watch: {
    $route: { handler(){ this.SET_TITLE('Task Schedule'); }, immediate: true },
  },
  methods: {
    ...mapActions("UI", ["SET_TITLE", 'CONFIRM']),
    ...mapActions("API", ["API"]),

    refreshSchedules(){
      this.schedules = [];
      return this.API({ method: 'GET', endpoint: 'schedules' })
      .then(schedules => {
        this.schedules = schedules;
        this.refreshTiming();
      })
    },

    OpenScheduleList(){ this.$refs.ScheduleList.open(); },

    OpenJobQueue(){ this.$refs.JobQueue.open(); },

    makeSortable(){
      if(this.sortable_) return; //already initialised
      this.sortable_ = new Sortable(this.$refs.sortable, {
        animation: 150,
        dataIdAttr: 'sortable-id',
        forceFallback: true,
        handle: '.dragMeColumn',
        onEnd: this.sorted,
      });
    },

    sorted(sortEvent){
      if(sortEvent.oldIndex == sortEvent.newIndex) return;
      let sort_order = this.sortable_.toArray();
      this.API({ method: 'POST', endpoint: 'schedules/sort', data: { sort_order } });
    },

    taskdrop(e){
      this.$refs.schedules.forEach(schedule => { schedule.taskDropped(e); });
      this.$refs.unscheduled.taskScheduled(e);
      this.refreshTiming();
    },

    deschedule(){
      this.$refs.unscheduled.refresh();
      this.refreshTiming();

    },

    async refreshTiming(){
      let timing = await this.API({ method: 'GET', endpoint: 'schedules/timing' });
      Object.keys(timing).forEach((k) => {
        timing[k] = { s: moment(timing[k].s), e: moment(timing[k].e) };
      });
      this.timing = timing;
    },

    highlighted(data){
      this.highlight = data == this.highlight ? null : data;
    },

    async autoplace2(tasks){
      await this.CONFIRM({ title: 'Place Tasks', message: `Place ${tasks.length} tasks?` });
      this.magic_text = `Placing Tasks: 0 of ${tasks.length}`;
      for(let i=0; i<tasks.length;i++){
        let t = tasks[i];
        this.magic_text = `Placing Tasks: ${i+1} of ${tasks.length}`;
        console.log("Placing Task:", t.type?.title);
        if(!t.type){ console.log("\t-", "No Type"); continue; } // no type
        let schedules = this.schedules.filter(s => s.task_types.filter(tt => tt.xid == t.type.xid).length);
        if(!schedules.length){ console.log("\t-", "No Schedules"); continue; } // no schedules available
        console.log("\t-", schedules);
      }
      this.magic_text = "";
    },

    async autoplace(){
      this.magic_text = 'Placing Tasks';
      this.API({ method: 'POST', endpoint: `schedules/autoplace` })
      .finally(() => { this.magic_text = ""; });
    },

    ClearJobQueue(){
      this.CONFIRM({ title: 'Clear Schedule', message: 'Are you sure you want clear the entire schedule?' })
      .then(() => {
        return this.API({ method: 'POST', endpoint: 'schedules/clear' })
      })
      .then(this.refreshSchedules);
    },

    async SortAll(){
      
      await this.CONFIRM({ title: 'Sort Schedules', message: 'This will sort all schedules by Forecast and Accepted Dates. Continue?' });
      for(let i = 0; i<this.schedules.length; i++){
        this.magic_text = `Sorting: (${i+1}/${this.schedules.length})`;
        await this.API({ method: 'POST', endpoint: `schedules/${this.schedules[i].xid}/auto_sort` });
      }
      this.magic_text = `Complete`;
      await this.refreshSchedules();
      this.magic_text = "";
    },

    async ClearOldJobTasks(){
      
      await this.CONFIRM({ title: 'Clear Old Job Tasks', message: 'This will remove all tasks where the job should no longer be scheduled. Continue?' });
      for(let i = 0; i<this.schedules.length; i++){
        this.magic_text = `Cleaning Up: (${i+1}/${this.schedules.length})`;
        await this.API({ method: 'POST', endpoint: `schedules/${this.schedules[i].xid}/clean` });
      }
      this.magic_text = `Complete`;
      await this.refreshSchedules();
      this.magic_text = "";
    },

    Print(){
      this.$refs.PrintSchedules.open();
    },

    showTaskflow(jobId){
      this.taskflowJobId = jobId;
      this.$nextTick(this.$refs.Taskflow.open);
    }

  },

  mounted(){
    this.$nextTick(() => { this.$parent.$parent.$refs.drawer.open = false });
    this.refreshSchedules();
    this.makeSortable();
  },

  beforeDestroy(){
    this.$parent.$parent.$refs.drawer.open = true;
  }


}
</script>
