<template>
  <div>

    <ListModal
      ref='Modal' title='Schedules'
      :load-items='loadSchedules' add-button @add-item='addItem'
      item-key='xid' item-text='title'
      sortable @sort='sortItems'
    >
      <template #actions='{item}'>
          <v-btn icon @click='renameItem(item)' class='float-left' title="Rename"><v-icon class='primary--text'>mdi-rename</v-icon></v-btn>
          <v-btn icon @click='editTasks(item)' class='float-left' title="Autoschedule Tasks"><v-icon class='primary--text'>mdi-chart-gantt</v-icon></v-btn>
          <v-btn icon @click='editDailyMinutes(item)' class='float-left' title="Available Minutes/Day"><v-icon class="primary--text">mdi-clock-outline</v-icon></v-btn>
          <v-btn icon @click='deleteItem(item)' class='float-left' title="Delete Schedule"><v-icon class='red--text'>mdi-trash-can</v-icon></v-btn>
      </template>
    </ListModal>

    <ListModal
      ref='AutoscheduleTaskModal' :title='`${autoschedule.schedule?.title}`'
      :load-items='loadTaskTypes'
      item-key='xid' item-text='title'
    >
      <template #actions="{item}">
        <v-checkbox v-model="autoschedule.task_types" :value="item.xid" @change="select_task_type(item)" />
      </template>
    </ListModal>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
//import Deferred from '@/helpers/Deferred'
import ListModal from '@/components/Common/ListModal'

export default {
  name: 'ScheduleList',
  components: {
    ListModal,
  },

  props: {},

  data(){
    return {
      autoschedule: {
        schedule: null,
        task_types: [],
      },
    }
  },

  computed: {},

  methods: {
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['CONFIRM', 'PROMPT']),

    open(){ this.$refs.Modal.open(); },

    loadSchedules(){ return this.API({ method: 'GET', endpoint: 'schedules' }); },

    loadTaskTypes(){ return this.API({ method: 'GET', endpoint: 'taskTypes' }); },

    async addItem(){
      let rq = this.API({ method: 'POST', endpoint: 'schedules/create' });
      this.$refs.Modal.load(rq, true);
      rq.then(() => { this.$emit('changed'); });
    },

    async deleteItem(item){
      await this.CONFIRM({ title: 'Delete Schedule', message: `Delete "${item.title}"?` });
      let rq = this.API({ method: 'DELETE', endpoint: `schedules/${item.xid}` });
      this.$refs.Modal.load(rq, true);
      rq.then(() => { this.$emit('changed'); });
    },

    async renameItem(item){
      let title = await this.PROMPT({ title: 'Rename Schedule', message: 'New name:', placeholder: item.title });
      if(title === item.title) return;
      let rq = this.API({ method: 'PATCH', endpoint: `schedules/${item.xid}`, data: { title } });
      this.$refs.Modal.load(rq, true);
      rq.then(() => { this.$emit('changed'); });
    },

    sortItems(sort_order){
      let rq = this.API({ method: 'POST', endpoint: 'schedules/sort', data: { sort_order } });
      this.$refs.Modal.load(rq, true);
      rq.then(() => { this.$emit('changed'); });
    },

    async editDailyMinutes(item){
      let daily_minutes = await this.PROMPT({ title: 'Schedule Duration', message: 'Daily shcedule duration in minutes:', placeholder: item.daily_minutes });
      if(daily_minutes === item.daily_minutes) return;
      let rq = this.API({
        method: 'PATCH',
        endpoint: `schedules/${item.xid}`,
        data: { daily_minutes }
      });
      this.$refs.Modal.load(rq, true);
    },

    editTasks(schedule){
      this.autoschedule.schedule = schedule;
      this.autoschedule.task_types = schedule.task_types.map(tt => tt.xid);
      this.$refs.AutoscheduleTaskModal.open();
    },

    select_task_type(task_type){
      let { schedule, task_types } = this.autoschedule;
      let selected = task_types.includes(task_type.xid);
      this.$refs.AutoscheduleTaskModal.loading = true;
      this.API({ method: selected ? 'PUT' : 'DELETE', endpoint: `schedules/${schedule.xid}/task_types/${task_type.xid}` })
      .then(tt => { schedule.task_types = tt; })
      .finally(() => { this.$refs.AutoscheduleTaskModal.loading = false; });
    },

  },
}
</script>
