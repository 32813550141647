var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column ScheduleColumn flex-shrink-0 flex-grow-0 mr-1"
  }, [_c('v-sheet', {
    attrs: {
      "color": "primary white--text mb-1 pa-1 rounded dragMeColumn",
      "elevation": "1"
    },
    on: {
      "contextmenu": _vm.rightClick
    }
  }, [_c('div', {
    staticClass: "text-subtitle-2 text-center"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('div', {
    staticClass: "flex-grow-1 overflow-y-auto my-1"
  }, [false ? void 0 : _vm._e(), _c('v-virtual-scroll', {
    attrs: {
      "items": [].concat(_vm.tasks, [{
        xid: null
      }]),
      "item-height": "80",
      "bench": "0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ScheduleTask', {
          staticClass: "mx-1",
          attrs: {
            "task": item,
            "timing": _vm.timing,
            "unscheduled": _vm.unscheduled,
            "highlight": _vm.highlight
          },
          on: {
            "move": _vm.moveTask,
            "updateTiming": _vm.updateTiming,
            "complete": _vm.completeTask,
            "deschedule": _vm.descheduleTask,
            "highlighted": _vm.highlighted,
            "clickEdit": function clickEdit($event) {
              return _vm.clickEdit(item);
            },
            "taskflow": function taskflow(v) {
              return _vm.$emit('taskflow', v);
            }
          }
        })];
      }
    }])
  })], 2), _c('v-menu', {
    ref: "menu_rightclick",
    attrs: {
      "position-x": _vm.contextmenu.x,
      "position-y": _vm.contextmenu.y,
      "absolute": "",
      "offset-y": ""
    },
    model: {
      value: _vm.contextmenu.show,
      callback: function callback($$v) {
        _vm.$set(_vm.contextmenu, "show", $$v);
      },
      expression: "contextmenu.show"
    }
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.print
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-printer")]), _vm._v("Print")], 1), _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.autosort
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-sort-calendar-ascending")]), _vm._v("Auto Sort")], 1), _c('v-list-item', {
    attrs: {
      "link": ""
    },
    on: {
      "click": _vm.clean
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "red"
    }
  }, [_vm._v("mdi-trash-can")]), _vm._v("Clean Up Old Tasks")], 1)], 1)], 1), _c('ScheduleTask', {
    staticClass: "mx-1",
    attrs: {
      "task": {
        xid: null
      }
    },
    on: {
      "move": _vm.moveTask
    }
  }), _c('EditTaskDialog', {
    ref: "editTaskDialog"
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }