<template>
  <div class="" >

    <!-- DROP ZONE -->
    <div class="dropHere rounded" :class="{ 'primary': dragover }"></div>

    <!-- BLANK CARD (END OF QUEUE) -->
    <template v-if="xid==null">
      <v-sheet class="pa-1 grey lighten-2 rounded" @dragenter="dragEnter" @dragleave="dragLeave" @dragover="dragOver" @drop="dragDrop" :class="{ 'interceptDrop': dragover }" style="opacity:1;">
        <div class="text-caption text-center grey--text text--darken-x font-italic">End of Queue</div>
      </v-sheet>
    </template>

    <!-- TASK CARD -->
    <template v-else>
      <v-card
        class="pa-1 dragMeTask" :class="{ 'interceptDrop': dragover, 'yellow lighten-3': isHighlighted }"
        draggable="true" @dragstart="dragStart"
        @dragenter="dragEnter" @dragleave="dragLeave"
        @dragover="dragOver" @drop="dragDrop"
       @contextmenu.prevent="menu_rightclick_show"
       @click="highlighted"
      >
        <v-card-text class="pa-0 ma-0">
          
          <div class="d-flex align-center justify-space-between">
            <template v-if="task.jobNumber">
              <v-chip label small class="mr-2" :color="colour" :title="message" @click.prevent="menu_leftclick_show">{{ task.jobNumber}}</v-chip>
            </template>
            <v-chip v-else label small class="mr-2">No Job</v-chip>
            <div class="text-caption primary--text">{{ timing[task.xid]?.s.format('DD/MM, HH:mm') ?? "" }}</div>
            <div class="text-caption" title="Days until Job F/C Date" v-if="promisedDeadline!==null">
              <span class="success--text" v-if="promisedDeadline > 0">{{ promisedDeadline }}</span>
              <span class="error--text" v-else>{{ promisedDeadline }}</span>
            </div>
            <a class="d-flex mr-1 align-center flex-columns primary--text text-caption" @click.stop="editDuration" title="Task Duration (minutes)">{{ task.duration }}</a>
          </div>

          <div class="d-flex px-1">
            <div v-if="task.type" class="text-subtitle-2 secondary--text flex-shrink-0">{{ task.type.title }}</div>
            <div v-else class="text-subtitle-2 secondary--text">Task</div>
            <div class="text-caption secondary--text ml-2 text-truncate flex-grow-1 text-center">{{ task.custName }}</div>
          </div>
          <div class="text-caption px-1 text-truncate" style="" :title="task.description">{{ task.description }}<!-- &nbsp adds space to force third line -->&nbsp;</div>

        </v-card-text>
      </v-card>
    </template>

    <!-- LEFT CLICK POPUP (TASK MENU) -->
    <v-menu
      v-model="contextmenu_show_right"
      :position-x="contextmenu_pos.x"
      :position-y="contextmenu_pos.y"
      absolute offset-y
      ref="menu_rightclick"
    >
      <v-list dense>
        
        <v-list-item link @click="$emit('clickEdit')"><v-icon color="primary" class="mr-2">mdi-pencil</v-icon>Edit</v-list-item>
        <v-divider />
        <v-list-item link @click="markComplete"><v-icon color="green" class="mr-2">mdi-check</v-icon>Complete</v-list-item>
        <v-divider />
        <v-list-item link @click="unschedule"><v-icon color="red" class="mr-2">mdi-trash-can</v-icon>Deschedule</v-list-item>
      </v-list>
    </v-menu>


    <!-- LEFT CLICK POPUP (JOB DETAILS) -->
    <v-menu
      v-model="contextmenu_show_left" :position-x="contextmenu_pos.x" :position-y="contextmenu_pos.y" absolute offset-y
      :close-on-content-click="false"
      ref="menu_leftclick"
    >
      <v-card class="d-block" width="400">
        <v-card-text class="py-0">
          <template v-if="job == null">
            Loading...
          </template>
          <template v-else>
            <v-list dense>
              <v-list-item two-line>
                <v-list-item-avatar><v-icon color="primary">mdi-briefcase</v-icon></v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="primary--text font-weight-medium">{{ job.number }}</v-list-item-title>
                  <v-list-item-subtitle>{{ job.customer?.name }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="text-caption">{{ job.customer_acc }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action><v-btn icon color="primary" @click="$emit('taskflow', job.xid)"><v-icon>mdi-source-merge</v-icon></v-btn></v-list-item-action>
                <v-list-item-action><v-btn icon color="primary" @click="openJobInNewTab"><v-icon>mdi-open-in-new</v-icon></v-btn></v-list-item-action>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-content class="justify-center text-subtitle-2">{{ job.description }}</v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium d-flex justify-center">
                    <v-icon v-if="job.stage?.menu_icon" class="mr-2" small>{{ job.stage?.menu_icon }}</v-icon>
                    <div>{{ job.stage?.title }}</div>
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">
                    <v-row class="text-center">
                      <v-col cols="6">ACC: {{ job.date_accepted }}</v-col>
                      <v-col cols="6">F/C: {{ job.date_promised }}</v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>
        </v-card-text>
      </v-card>
    </v-menu>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ScheduleColumn{
  width: 300px;

}

.dragMeTask{
  cursor: move;
}

.dropHere{
  height:6px;
}

.interceptDrop * { pointer-events: none; }

</style>

<script>
import { /* mapGetters, */  mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'ScheduleTask',
  components: {},

  props: {
    task: { type: Object, default: null, required: true },
    timing: { type: Object, default: Object },
    unscheduled: { type: Array, default: Array },
    highlight: { type: String, default: null },
  },

  data(){
    return {
      dragover: false,
      job: null,

      contextmenu_show_left: false,
      contextmenu_show_right: false,
      contextmenu_pos: {
        x: null,
        y: null,
      },

    }
  },

  computed: {
    ...mapGetters("UI", ["CONTEXT_MENU"]),
    xid(){ return this.task.xid; },
    colour(){
      if(this.dependents_scheduled_too_late.length > 0) return 'warning';
      if(this.dependents_unscheduled.length > 0) return 'error';
      return 'primary';
    },
    message(){
      if(this.dependents_scheduled_too_late.length > 0) return 'Required tasks may not be complete';
      if(this.dependents_unscheduled.length > 0) return 'Required tasks have not been scheduled';
      return '';
    },
    dependents_unscheduled(){
      return this.task.dependents?.filter(t => this.unscheduled.includes(t));
    },
    dependents_scheduled_too_late(){
      if(this.timing[this.task.xid] == undefined) return false;
      return this.task.dependents.filter(d => {
        if(this.timing[d] == undefined) return false;
        return this.timing[this.task.xid].s.isBefore(this.timing[d].e);
      });
    },
    isHighlighted(){ return this.highlight && this.highlight == this.task?.jobId;  },
    promisedDeadline(){
      if(!this.task.jobPromised) return null;
      if(!this.timing[this.task.xid]) return null;
      return moment(this.task.jobPromised).diff(this.timing[this.task.xid].s.clone().startOf('day'), 'days');
    }

  },

  watch: {
    CONTEXT_MENU(component){
      this.contextmenu_show_left = component === this.$refs.menu_leftclick;
      this.contextmenu_show_right = component === this.$refs.menu_rightclick;
    },

    contextmenu_show_left(show){
      if(show) this.loadJobDetails();
      if(!show) this.job = null;
    }

  },

  methods: {
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['CONFIRM', 'SET_CONTEXT_MENU', 'PROMPT']),

    dragStart(e){
      e.dataTransfer.setData("move-task", JSON.stringify({
        task: this.task,
      }));
        e.dataTransfer.setDragImage(new Image(), 10, 10);
    },

    dragEnter(){ this.dragover = true; },
    dragLeave(){ this.dragover = false; },
    dragOver(e){ e.preventDefault(); },
    dragDrop(e){
      this.dragover = false;
      let { task } = JSON.parse(e.dataTransfer.getData("move-task"));
      if(task) this.$emit("move", { task, to: this.xid });
      e.preventDefault();
    },

    loadJobDetails(){
      this.API({ method: 'GET', endpoint: `jobs/${this.task.jobId}` })
      .then(job => {
        if(!this.contextmenu_show_left) return; // popup is no longer active
        this.job = job;
      });
    },

    openJobInNewTab(){ window.open(`/dash/jobs/all/${this.job.xid}`, '_blank'); },

    unschedule(){ this.$emit('deschedule', this.task); },

    markComplete(){ this.$emit('complete', this.task); },

    menu_leftclick_show(e){
      this.contextmenu_pos = { x: e.clientX, y: e.clientY };
      this.SET_CONTEXT_MENU(this.$refs.menu_leftclick);
    },

    menu_rightclick_show(e){
      this.contextmenu_pos = { x: e.clientX, y: e.clientY };
      this.SET_CONTEXT_MENU(this.$refs.menu_rightclick);
    },

    async editDuration(){
      let duration = await this.PROMPT({ title: 'Task Duration', message: 'Duration in minutes:', placeholder: this.task.duration });
      if(duration === this.task.duration) return;
      this.loading = true;
      this.API({ method: 'PATCH', endpoint: `tasks/${this.task.xid}`, data: { duration } })
      .then(() => {
        this.task.duration = duration;
        this.$emit('updateTiming');
      })
      .finally(() => { this.loading = false; });
    },

    highlighted(){
      this.$emit('highlighted', this.task.jobId);
    },

  },

  mounted(){
    
  }
}
</script>
