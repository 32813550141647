var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "d-flex flex-column flex-nowrap pa-0",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('div', {
    staticClass: "flex-grow-1 overflow-auto",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "id": "scrollArea"
    }
  }, [_c('div', {
    ref: "sortable",
    staticClass: "d-flex overflow-x-auto fill-height flex-grow-0 pa-1"
  }, _vm._l(_vm.schedules, function (s) {
    return _c('ScheduleColumn', {
      key: s.xid,
      ref: "schedules",
      refInFor: true,
      attrs: {
        "sortable-id": s.xid,
        "schedule": s,
        "timing": _vm.timing,
        "unscheduled": _vm.unscheduledTasks,
        "highlight": _vm.highlight
      },
      on: {
        "taskdrop": _vm.taskdrop,
        "complete": _vm.refreshTiming,
        "deschedule": _vm.deschedule,
        "highlighted": _vm.highlighted,
        "taskflow": _vm.showTaskflow
      }
    });
  }), 1)]), _c('div', {
    staticStyle: {
      "width": "100%",
      "position": "relative"
    },
    attrs: {
      "id": "toolbarArea"
    }
  }, [_c('v-divider'), _c('div', {
    staticClass: "grey lighten-3 pa-2 text-caption"
  }, [_c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary mr-4"
    },
    on: {
      "click": _vm.OpenScheduleList
    }
  }, [_vm._v("Manage Schedules")]), _c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary mr-4"
    },
    on: {
      "click": _vm.ClearJobQueue
    }
  }, [_vm._v("Clear All")]), _c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary mr-4"
    },
    on: {
      "click": _vm.ClearOldJobTasks
    }
  }, [_vm._v("Clear Old")]), _c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary mr-4"
    },
    on: {
      "click": _vm.OpenJobQueue
    }
  }, [_vm._v("Job Queue")]), _c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary mr-4"
    },
    on: {
      "click": _vm.SortAll
    }
  }, [_vm._v("Sort All")]), _c('v-btn', {
    attrs: {
      "small": "",
      "color": "primary mr-4"
    },
    on: {
      "click": _vm.Print
    }
  }, [_vm._v("Print")])], 1)], 1), _c('ScheduleList', {
    ref: "ScheduleList",
    on: {
      "changed": _vm.refreshSchedules
    }
  }), _c('JobQueue', {
    ref: "JobQueue",
    on: {
      "schedulesChanged": _vm.refreshSchedules,
      "taskflow": _vm.showTaskflow
    }
  }), _c('PrintSchedules', {
    ref: "PrintSchedules"
  }), _c('Taskflow', {
    ref: "Taskflow",
    attrs: {
      "type": "job",
      "id": _vm.taskflowJobId
    }
  }), _c('v-overlay', {
    attrs: {
      "absolute": "",
      "value": _vm.magic_text
    }
  }, [_c('v-card', {
    attrs: {
      "light": "",
      "width": "400px"
    }
  }, [_c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-progress-circular', {
    staticClass: "my-4",
    attrs: {
      "size": 100,
      "color": "primary",
      "indeterminate": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-auto-fix")])], 1), _c('div', {
    staticClass: "my-6 secondary--text"
  }, [_vm._v(_vm._s(_vm.magic_text))])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }