var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.PERMS.includes('TASKFLOW_VIEW') ? _vm._t("button", null, null, {
    click: _vm.open
  }) : _vm._e(), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "width": "1500px",
      "scrollable": ""
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text py-2"
  }, [_vm._v("Taskflow "), _c('v-spacer')], 1), _c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "height": "1000px"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0"
  }, [_c('v-col', {
    staticClass: "overflow-y-auto fill-height",
    attrs: {
      "cols": "3"
    }
  }, [_vm.task ? [_c('v-select', {
    attrs: {
      "items": _vm.taskTypes,
      "item-text": "title",
      "item-value": "xid",
      "value": _vm.task.type,
      "return-object": "",
      "label": "Type",
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "clearable": ""
    },
    on: {
      "input": _vm.changeTaskType
    }
  }), _c('div', {
    staticClass: "mt-4"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": _vm.editDuration
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-clock-outline")]), _vm._v(_vm._s(_vm.task.duration) + " minutes")], 1), _c('v-checkbox', {
    staticClass: "mt-1 ml-3",
    attrs: {
      "label": "Schedule",
      "hide-details": ""
    },
    on: {
      "change": function change() {
        return _vm.updateTask(_vm.task.xid);
      }
    },
    model: {
      value: _vm.task.active,
      callback: function callback($$v) {
        _vm.$set(_vm.task, "active", $$v);
      },
      expression: "task.active"
    }
  })], 1), _c('v-textarea', {
    staticClass: "mt-4",
    attrs: {
      "value": _vm.task.description,
      "outlined": "",
      "dense": "",
      "hide-detadils": "",
      "label": "Description",
      "counter": "",
      "maxlength": "255"
    },
    on: {
      "input": _vm.changeDescription
    }
  }), _c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('v-btn', {
    staticClass: "primary--text",
    attrs: {
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.completeTask
    }
  }, [_vm._v(_vm._s(_vm.task.completed ? "Incomplete" : "Complete"))]), _c('v-btn', {
    staticClass: "red--text",
    attrs: {
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.deleteTask
    }
  }, [_vm._v("Delete")])], 1), _c('v-alert', {
    staticClass: "mt-4",
    attrs: {
      "color": "primary",
      "outlined": "",
      "icon": "mdi-information",
      "dense": ""
    }
  }, [_c('b', [_vm._v("Ctrl+Click")]), _vm._v(" to link/unlink items. ")])] : [_c('v-btn', {
    staticClass: "primary",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.newTask
    }
  }, [_vm._v("New Task")]), _c('p', {
    staticClass: "text-center mt-4"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "small": "",
      "color": "primary"
    },
    on: {
      "click": function click() {
        _vm.$refs.ImportTaskflow.open();
      }
    }
  }, [_vm._v("Import Preset Taskflow")]), _c('ListModal', {
    ref: "ImportTaskflow",
    attrs: {
      "title": "Import Taskflow",
      "item-text": "title",
      "loadItems": function loadItems() {
        return _vm.API({
          method: 'GET',
          endpoint: 'taskflows'
        });
      }
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-icon', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.importTaskflow(item.xid);
            }
          }
        }, [_vm._v("mdi-import")])];
      }
    }])
  })], 1)]], 2), _c('v-col', {
    staticClass: "pa-0 grey lighten-2",
    staticStyle: {
      "max-height": "100%"
    },
    attrs: {
      "cols": "9"
    }
  }, [_vm.isOpen ? _c('Chart', {
    ref: "chart",
    attrs: {
      "blocks": _vm.blocks
    },
    on: {
      "link": _vm.link,
      "move": _vm.move,
      "select": _vm.updateSelected
    },
    scopedSlots: _vm._u([{
      key: "block",
      fn: function fn(_ref2) {
        var block = _ref2.block,
          selected = _ref2.selected;
        return _vm._l([_vm.tasks.find(function (t) {
          return t.xid == block.id;
        })], function (task) {
          var _task$scheduleInfo, _task$scheduleInfo2;
          return _c('div', {
            key: task.xid,
            staticStyle: {
              "height": "100%"
            }
          }, [_c('v-card', {
            staticClass: "taskCard fill-height pa-1",
            class: {
              selected: selected
            },
            attrs: {
              "disabled": !task.active
            }
          }, [_c('div', {
            staticClass: "d-flex"
          }, [_c('span', {
            staticClass: "my-0 text-truncate font-weight-medium primary--text",
            class: {
              'primary--text': !task.completed,
              'success--text': task.completed
            }
          }, [task.type ? [_vm._v(_vm._s(task.type.title))] : [_vm._v("Task")]], 2), _c('v-spacer'), _c('div', {
            staticClass: "d-flex mr-1 align-center flex-columns"
          }, [task.completed ? [_c('v-icon', {
            attrs: {
              "small": "",
              "color": "success"
            }
          }, [_vm._v("mdi-check")])] : [_c('v-icon', {
            staticClass: "mr-1 primary--text",
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-clock-outline")]), _c('div', {
            staticClass: "primary--text"
          }, [_vm._v(_vm._s(task.duration))])]], 2)], 1), _c('p', {
            staticClass: "my-0 pa-0 text-truncate text-caption black--text"
          }, [_vm._v(" " + _vm._s(task.description) + "  ")]), _c('p', {
            staticClass: "my-0 pa-0 text-truncate text-caption"
          }, [(_task$scheduleInfo = task.scheduleInfo) !== null && _task$scheduleInfo !== void 0 && _task$scheduleInfo.scheduleId ? _c('span', [_c('v-icon', {
            attrs: {
              "x-small": "",
              "color": "primary"
            }
          }, [_vm._v("mdi-chart-timeline")]), _vm._v(" "), _c('span', {
            staticClass: "font-italic text-caption"
          }, [_vm._v(_vm._s(task.scheduleInfo.scheduleName))])], 1) : _c('span', [_c('v-icon', {
            attrs: {
              "x-small": ""
            }
          }, [_vm._v("mdi-chart-timeline")]), _vm._v(" "), _c('span', {
            staticClass: "font-italic text-caption"
          }, [_vm._v("Not Scheduled")])], 1), (_task$scheduleInfo2 = task.scheduleInfo) !== null && _task$scheduleInfo2 !== void 0 && _task$scheduleInfo2.ETA ? _c('span', [_vm._v("  "), _c('span', {
            staticClass: "text-caption"
          }, [_vm._v(_vm._s(_vm.localTimestamp(task.scheduleInfo.ETA.s).format('DD/MM/YY')))])]) : _vm._e()])])], 1);
        });
      }
    }], null, false, 654892077)
  }) : _vm._e()], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    on: {
      "click": function click($event) {
        _vm.isOpen = false;
      }
    }
  }, [_vm._v("Done")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }