<template>
  <div>

    <ListModal
      ref='Modal' title='Print'
      :load-items='loadItems'
      item-key='xid' item-text='title'
      dense selectable
      action="Print" @action="print"
    >
    </ListModal>

  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<script>
import { /* mapGetters, */  mapActions } from 'vuex';
import PrintJS from 'print-js'
//import Deferred from '@/helpers/Deferred'
import ListModal from '@/components/Common/ListModal'

export default {
  name: 'ScheduleList',
  components: {
    ListModal,
  },

  props: {},

  data(){
    return {

    }
  },

  computed: {},

  methods: {
    ...mapActions("API", ['API']),
    ...mapActions("UI", ['ALERT', 'CONFIRM']),

    open(){ this.$refs.Modal.open(); },

    loadItems(){ return this.API({ method: 'GET', endpoint: 'schedules' }); },

    async print(){
      let schedules = this.$refs.Modal.selected;
      if(schedules.length == 0){
        this.ALERT({ title: "Print Schedules", message: "No shcedules selected." });
        return;
      }
      let waiting = await this.CONFIRM({ title: "Print Schedules", message: "Do you want to include tasks waiting?", other: 'No', no: 'Cancel' });
      waiting = waiting == 'yes';
      let data = { schedules, waiting };
      this.API({ method: 'POST', endpoint: `scheduling/print`, data })
      .then(pdf => {
        PrintJS({ printable: pdf, type: 'pdf', base64: true });
      });
    }

  },
}
</script>
